*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
